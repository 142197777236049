@import url('https://fonts.googleapis.com/css?family=Numans');

body {
    height: 100%;
    background: #ddeffe;
}

#root {
    /*background: ##ddeffe;*/
    background-image: url(../src/imgs/banner_mapa.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

html {
    height: 100%;
    background-image: url(../src/imgs/banner_mapa.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}

.container {
    max-width: 92% !important;
}

/*body {
    margin: 0;
    padding: 0;
    font-family: 'Numans', sans-serif;
    background-image: none;
    background: #B2DFDB;
    //#e8ebf0//
}*/

.card {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 12px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 12px 20px 0 rgba(0, 0, 0, 0.19);
    -moz-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2), 0 12px 20px 0 rgba(0, 0, 0, 0.19);
}

.container {
    padding: 10px;
}

.left {
    display: flex;
    align-content: flex-start;
}

.right {
    display: flex;
    align-content: flex-end;
}

.card-header {
    background-image: url('imgs/banner_mapa.jpg') !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-weight: 500;
}

.breadcrumb {
    background-color: transparent !important;
    margin-bottom: 0 !important;
    padding: 0.2rem !important;
    font-weight: 500;
}

/*INICIO NAV BAR*/
.dropdown-item {
    padding: 2px 5px 2px 5px !important;
    margin: 0px !important;
    font-size: 15px;
}

.nav-link {
    color: #212121 !important;
}

.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #CFD8DC !important;
}

/*FIM NAV BAR*/

.indigo {
    color: #3F51B5 !important
}

.azul {
    color: #2196F3 !important
}

.selectgroup {
    display: inline-flex
}

.selectgroup-item {
    flex-grow: 1;
    position: relative;
    font-weight: 400 !important
}

    .selectgroup-item + .selectgroup-item {
        margin-left: -1px
    }

    .selectgroup-item:not(:first-child) .selectgroup-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .selectgroup-item:not(:last-child) .selectgroup-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

.selectgroup-input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0
}

.selectgroup-button {
    display: block;
    border: 1px solid rgba(0,40,100,.12);
    text-align: center;
    margin: .2rem;
    padding: .375rem 1rem;
    position: relative;
    cursor: pointer;
    border-radius: 3px;
    color: #9aa0ac;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    line-height: 1.5rem;
    min-width: 2.375rem
}

.selectgroup-button-icon {
    padding-left: .5rem;
    padding-right: .5rem;
    font-size: 1rem
}

.selectgroup-input:checked + .selectgroup-button {
    border-color: #1572e8;
    z-index: 1;
    color: #1572e8;
    background: rgba(21,114,232,.15)
}

.selectgroup-input:focus + .selectgroup-button {
    border-color: #1572e8;
    z-index: 2;
    color: #1572e8;
    box-shadow: 0 0 0 2px rgba(21,114,232,.25)
}

.selectgroup-pills {
    flex-wrap: wrap;
    align-items: flex-start
}

    .selectgroup-pills .selectgroup-item {
        margin-right: .5rem;
        flex-grow: 0
    }

    .selectgroup-pills .selectgroup-button {
        border-radius: 50px !important
    }

.selectgroup.selectgroup-primary .selectgroup-input:checked + .selectgroup-button {
    border-color: #1572e8;
    color: #1572e8;
    background: rgba(21,114,232,.15)
}

.selectgroup.selectgroup-primary .selectgroup-input:focus + .selectgroup-button {
    border-color: #1572e8;
    color: #1572e8;
    box-shadow: 0 0 0 2px rgba(21,114,232,.25)
}

.selectgroup.selectgroup-secondary .selectgroup-input:checked + .selectgroup-button {
    border-color: #6861ce;
    color: #6861ce;
    background: rgba(104,97,206,.15)
}

.selectgroup.selectgroup-secondary .selectgroup-input:focus + .selectgroup-button {
    border-color: #6861ce;
    color: #6861ce;
    box-shadow: 0 0 0 2px rgba(104,97,206,.25)
}

.selectgroup.selectgroup-info .selectgroup-input:checked + .selectgroup-button {
    border-color: #48abf7;
    color: #48abf7;
    background: rgba(72,171,247,.15)
}

.selectgroup.selectgroup-info .selectgroup-input:focus + .selectgroup-button {
    border-color: #48abf7;
    color: #48abf7;
    box-shadow: 0 0 0 2px rgba(72,171,247,.25)
}

.selectgroup.selectgroup-success .selectgroup-input:checked + .selectgroup-button {
    border-color: #31ce36;
    color: #31ce36;
    background: rgba(49,206,54,.15)
}

.selectgroup.selectgroup-success .selectgroup-input:focus + .selectgroup-button {
    border-color: #31ce36;
    color: #31ce36;
    box-shadow: 0 0 0 2px rgba(49,206,54,.25)
}

.selectgroup.selectgroup-warning .selectgroup-input:checked + .selectgroup-button {
    border-color: #ffad46;
    color: #ffad46;
    background: rgba(255,173,70,.15)
}

.selectgroup.selectgroup-warning .selectgroup-input:focus + .selectgroup-button {
    border-color: #ffad46;
    color: #ffad46;
    box-shadow: 0 0 0 2px rgba(255,173,70,.25)
}

.selectgroup.selectgroup-danger .selectgroup-input:checked + .selectgroup-button {
    border-color: #f25961;
    color: #f25961;
    background: rgba(242,89,97,.15)
}

.selectgroup.selectgroup-danger .selectgroup-input:focus + .selectgroup-button {
    border-color: #f25961;
    color: #f25961;
    box-shadow: 0 0 0 2px rgba(242,89,97,.25)
}

.selectgroup-input:checked + .selectgroup-button {
    z-index: 0 !important;
}
